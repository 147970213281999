.Footer {
    width: 100%;
    height: 100%;
    margin-top: 5rem;
    background-color: #010129;
    color: white;

}

.cFooter {
    display: flex;
    width: 100%;
    justify-content: space-around;
    padding: 10px;
}

.logo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
}

.logo>img {
    width: 10rem;
    height: 10rem;
}

.logo>span {
    font-weight: 600;
    font-size: 1.5rem;
    text-transform: uppercase;
    font-style: italic;
    font-family: cursive;
    text-align: center;

}

.block {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 12rem;
    padding: 10px;

}

.details {
    display: flex;
    flex-direction: column;
    width: inherit;
    font-size: 0.7rem;
    gap: 1rem;
    font-style: italic;
}

.details>span:nth-of-type(1) {
    font-weight: 500;
    font-style: normal;
    font-size: 1.2rem;
}

.pngLine:hover {
    cursor: pointer;
}

.pngLine {
    display: flex;
    gap: rem;
    align-items: center;
}

.copy {
    text-align: center;
    margin-top: 1rem;
    font-size: 1rem;
    font-family: cursive;
    font-style: italic;
    color: #babaed;
}

