.container{
    padding: 0rem 2rem;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;

}

.container>:nth-child(1){
    position: absolute;
    width: 8rem;
    left: 30%;
    top: -3rem;
}

.products{
    display: grid;
    width: 90%;
    grid-template-columns: 25% auto;
    column-gap: 2rem;
}

.menu{
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    font-weight: 500;
    font-size: 1.3rem;  
}

.menu li{
    width: 50%;
    background-color: white;
    padding: 15px 15px;
    border-radius: 10px;
    text-transform: uppercase;
 
}

.menu>li:hover{
    color: #ed0d33;
    cursor: pointer;
}


.list{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 2fr));
    height: 25rem;
    overflow-y: hidden;
    grid-gap: 2rem;
    justify-content: space-between;
    overflow-y: scroll;

}

.product{
    width: 15rem;
    height: 8rem;
    background-color: white;
    position: relative;
  overflow: hidden;
    padding: 1rem;
    display: flex;
    border-radius: 1rem;
}

.product>img{
    top: 3rem;
    width: 6rem;
    height: 11rem;
    text-align: right;

}


@media screen and (max-width:856px) {
    .products{
       display: flex;
       flex-direction: column;
    
    }
    .menu{
        width: 50%;
        display: grid;
        grid-template-columns: 100% auto;
    }
    .menu>li{
        display: block;
        width: 200px;
        text-align: center;
       
    }
    .list{
        margin-top: 50px;
       
    }
}