.container{
    padding: 0 2rem;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    
}


.wrapper{
    display: flex;
    align-items: baseline;
    justify-content: center;
    position: relative;
}

.wrapper>img{
    width: 50rem;
    position: absolute;
    top: 30px;
}

.blueCircle{
    width: 28rem;
    height: 28rem;
    border-radius: 50%;
    border-bottom-left-radius: 15px;
    border-top-right-radius: 20px;
    border: 3px solid #5a5aef;
    z-index: -99;
    position: absolute;
    bottom: 10px;
    top: 1px;
    background-color: rgb(233, 233, 15);
}

.line{
    width: 2rem;
    height: 28rem;
    /* border-radius: 100%; */
    z-index: -99;
    position:absolute;
    bottom: 15px;
    top:3px;
    background-color: #5a5aef;
}
.line{
    width: 2rem;
    height: 28rem;
    /* border-radius: 100%; */
    z-index: -99;
    position:absolute;
    bottom: 15px;
    top: 1px;
    background-color: #5a5aef;
}
.line1{
    width: 2rem;
    height: 26rem;
    /* border-radius: 100%; */
    z-index: -99;
    position:absolute;
    bottom: 15px;
    top: 33px;
    left: 35%;
    border-top-left-radius: 24px;
    background-color: #5a5aef;
}


.h_sides{
    display: grid;
    grid-template-rows: 1fr 1fr;
    
}

.h_sides>span{
    display: block;
    font-size: 22px;
    font-weight: 700;
    width: min-content;
    text-transform: uppercase;
}

.text2{
    display: flex;
    flex-direction: column;
    width: min-content;
    color: black;
   
}


.text2>:first-child{
    font-weight: 800;
    font-size: 2.5rem;
    text-transform: uppercase;
}

.text2>:last-child{
    display: block;
}

.traffic{
    display: flex;
    flex-direction: column;
    text-align: right;
}

.traffic>:first-child{
    font-weight: 800;
    font-size: 2.5rem;
}

.customers{
    display: flex;
    flex-direction: column;
    text-align: right;

}

.customers>:first-child{
    font-weight: 800;
    font-size: 2.5rem;
}

.cart2{
    position: absolute;
    bottom: 35%;
    right: -5%;
    display: flex;
    gap: 1rem;
    align-items: center;
}

.cart2>svg{
    height: 25px;
    width: 25px;
    background-color: white;
    padding: 10px;
    border-radius: 50%;
    border: 6px solid black;
}

.signup{
    display: flex;
    gap: 1rem;
    align-items: center;
    padding: 10px;
    background-color: wheat;
    border-radius: 15px;
    font-size: 0.8rem;
    box-shadow: 1px 2px 15px #020217;
}

.signup>:first-child{
    display: block;
    width: 6rem;
}

.signup>:last-child{
    border: 1px solid slateblue;
    border-radius: 50%;
  display: flex ;
  height: 20px;
  width: 20px;
  padding: 5px;
  align-items: center;
  justify-content: center;
}


@media screen and (max-width: 856px) {
    .text2>span:nth-child(1){
        font-size: 1.5rem;
    }

    .wrapper{
       
        position: relative;
    }

    .cart2{
        position: absolute;
      bottom: -10%;
      right: -40%;
    
    }
    
    .line{
        display: none;
    }
  
    .blueCircle{
        width: 25rem;
        height: 28rem;
        border-radius: 50%;
        z-index: -99;
        position: absolute;
        bottom: 10px;
        top: -20px;
       
    }

    
    
}