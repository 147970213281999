.virtual{
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding:0rem 3rem;
    margin-top: 6rem;
    margin-bottom: 6rem;
}

.left{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    position: relative;
    
}

.left>:nth-child(1){
    font-size: 2rem;
    text-transform: uppercase;
    display: block;
 
    font-weight: 700;
}

.left>:nth-child(2){
    font-size: 1.5rem;
    text-transform: uppercase;
    display: block;
    width: 20rem;
}

.left>:nth-child(3){
    font-size: 1.4rem;
    font-weight: bold;
}

.left>img{
    position: absolute;
    width: 12rem;
   top: 13rem;

}

.wrapper{
    width: 30rem;
}

@media screen and (max-width:856px) {
    .virtual{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding:0rem 0rem;

   
}

    .left{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    position: relative;
    
}

.left>:nth-child(1){
    font-size: 3rem;
    text-transform: uppercase;
    display: block;
    font-weight: 800;
}

.left>:nth-child(2){
    font-size: 1.2rem;
    text-transform: uppercase;
    display: block;
    width: 20rem;
}

.left>:nth-child(3){
    font-size: 1.3rem;
    font-weight: bold;
}
.left>img{
    display: none;

}
    

}